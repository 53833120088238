import React from "react";
import { Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";

function MobilePrivacyPolcy() {
  const mainPoint = {
    paddingLeft: 0,
  };
  const secondaryPoint = {
    paddingLeft: "25px",
  };

  return (
    <Grid
      container
      style={{
        paddingTop: "50px",
        paddingBottom: "50px",
        paddingLeft: "5px",
        paddingRight: "5px",
      }}
    >
      <Helmet>
        <title>Centrum Medyczne FemiMed - Polityka Prywatności</title>
      </Helmet>

      <Grid
        container
        item
        xs={12}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "25px",
          paddingBottom: "25px",
        }}
        justify="center"
        component={Paper}
        direction={"column"}
      >
        <Grid item xs>
          <Typography variant={"h3"} component={"h2"} align={"center"} style={{fontWeight:"500"}}>
            Polityka Prywatności
          </Typography>
        </Grid>

        <Grid item container xs style={{ paddingTop: "50px" }}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant={"body1"} align={"justify"}>
              <b>
                Klauzula informacyjna w związku ze świadczeniem usług medycznych
                (art. 13 RODO)
              </b>
            </Typography>
            <ol start="1" style={mainPoint}>
              <li>
                <Typography>
                  Administratorem podanych danych jest Centrum Medyczne FemiMed
                  Sp. z o.o. ul. Kielecka 38A w Sosnowcu.
                </Typography>
              </li>
              <li>
                <Typography>
                  Pani/Pana dane osobowe są przetwarzane przez Centrum Medyczne
                  FemiMed w następujących celach i na następujących podstawach
                  prawnych:
                </Typography>
                <ol start="1" style={secondaryPoint}>
                  <li>
                    <Typography>
                      udzielenia Pani/Panu świadczeń medycznych lub objęcia
                      opieką zdrowotną w ramach porad lekarskich i zapewnienia
                      możliwości korzystania ze świadczeń zdrowotnych , a także
                      w celach rezerwacji, potwierdzania i odwoływania
                      konsultacji lekarskich lub badań diagnostycznych,
                      informowania Pani/Pana o zarezerwowanych konsultacjach
                      lekarskich lub badaniach diagnostycznych, o wynikach lub
                      innych sprawach związanych z udzielanymi lub planowanymi
                      świadczeniami zdrowotnymi – na podstawie art. 6 ust. 1
                      lit. f w związku z art. 9 ust. 2 lit. h rozporządzenia
                      Parlamentu Europejskiego i Rady (UE) z dnia 27 kwietnia
                      2016 r. w sprawie ochrony osób fizycznych w związku z
                      przetwarzaniem danych osobowych i w sprawie swobodnego
                      przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                      (ogólne rozporządzenie o ochronie danych) (Dz. Urz. UE L
                      119 z 4.5.2016 r., s. 1) (dalej: „<b>RODO</b>”);
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      weryfikacji Pani/Pana tożsamości przed udzieleniem
                      świadczenia zdrowotnego oraz prowadzenia, udostępniania i
                      przechowywania dokumentacji medycznej – na podstawie art.
                      6 ust. 1 lit. c w związku z art. 9 ust. 2 lit. h RODO i
                      art. 24 ust. 1 i n. ustawy z dnia 6 listopada 2008 r. o
                      prawach pacjenta i Rzeczniku Prawa Pacjenta (Dz. U. z 2017
                      poz. 1318 i 1524);
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      dokonywania rozliczeń związanych z udzielaniem świadczeń
                      zdrowotnych i dochodzeniem roszczeń związanych z
                      prowadzeniem działalności gospodarczej – na podstawie art.
                      6 ust. 1 lit. b, c, f w związku z art. 9 ust. 2 lit. h
                      RODO;
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      prowadzenia ksiąg rachunkowych – na podstawie art.6 ust. 1
                      lit. c RODO w związku z art. 74 ust. 2 ustawy z dnia 29
                      września 1994 r. o rachunkowości (Dz. U. z 2018 r. poz.
                      395).
                    </Typography>
                  </li>
                </ol>
              </li>
              <li>
                <Typography>
                  Pani/Pana dane osobowe są lub mogą być przekazywane innym
                  podmiotom udzielającym świadczeń zdrowotnych. Państwa dane
                  osobowe nie będą przekazywane do państwa trzeciego ani do
                  organizacji międzynarodowej.
                </Typography>
              </li>
              <li>
                <Typography>
                  Pani/Pana dane osobowe będą przechowywane :
                </Typography>
                <ol start="1" style={secondaryPoint}>
                  <li>
                    <Typography>
                      w przypadku przetwarzania danych, o którym mowa w pkt. 2
                      ppkt. 2 - przez okres wymagany przepisami ustawy o prawach
                      pacjenta i Rzeczniku Praw Pacjenta, tj. przez 20 lat,
                      zgodnie z art. 29 ust. 1 ustawy o prawach pacjenta i
                      Rzeczniku Praw Pacjenta z zastrzeżeniem wyjątków
                      przewidzianych w ustawie (art. 29 ust. 1 pkt. 1a, 2, 3 i 4
                      ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta)
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      w przypadku przetwarzania danych, o którym mowa w pkt. 2
                      ppkt. 1 i 3 – do upływu okresu przedawnienia roszczeń
                      związanych z prowadzeniem działalności gospodarczej;
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      w przypadku przetwarzania danych, o którym mowa w pkt. 2
                      ppkt. 4 – przez okres wymagany przepisami ustawy o
                      rachunkowości.
                    </Typography>
                  </li>
                </ol>
              </li>
              <li>
                <Typography>
                  Przysługuje Pani/Panu prawo do żądania dostępu do swoich
                  danych osobowych, ich sprostowania, usunięcia lub ograniczenia
                  ich przetwarzania. Ma Pani/Pan prawo wniesienia skargi
                  dotyczącej przetwarzania Pani/Pana danych osobowych do organu
                  nadzorczego. Pani/Pana dane osobowe nie będą wykorzystywane do
                  zautomatyzowanego podejmowania decyzji wywołujących wobec
                  Pani/Pana skutki prawne.
                </Typography>
              </li>
              <li>
                <Typography>
                  Podanie danych osobowych w przypadkach, o których mowa w pkt.
                  2 ppkt 1 jest dobrowolne. W przypadkach, o których mowa w pkt.
                  2 ppkt 2, obowiązek podania danych wynika ze wskazanych tam
                  przepisów prawa. Niepodanie przez Panią/Pana danych będzie
                  skutkowało brakiem możliwości realizacji świadczeń
                  zdrowotnych.
                </Typography>
              </li>
            </ol>
            <Typography>
              Klauzula informacyjna w związku ze zastosowaniem monitoringu w
              placówce (art. 13 RODO)
            </Typography>
            <ol start="1" style={mainPoint}>
              <li>
                <Typography>
                  Administratorem podanych danych jest Centrum Medyczne FemiMed
                  Sp. z o.o. ul. Kielecka 38A w Sosnowcu.
                </Typography>
              </li>
              <li>
                <Typography>
                  Pani/Pana dane osobowe są przetwarzane przez FemiMed Centrum
                  Medyczne w Sosnowcu poprzez system monitoringu ze względu  na
                  bezpieczeństwo osób i ochrony mienia na podstawie art. 6 ust.1
                  lit. f rozporządzenia Parlamentu Europejskiego i Rady (UE) z
                  dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
                  związku z przetwarzaniem danych osobowych i w sprawie
                  swobodnego przepływu takich danych oraz uchylenia dyrektywy
                  95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz. Urz.
                  UE L 119 z 4.5.2016 r., s. 1).
                </Typography>
              </li>
              <li>
                <Typography>
                  Pani/Pana  dane osobowe będą przechowywane przez okres 1
                  miesiąca, a następnie ulegną całkowitemu usunięciu z systemu.
                </Typography>
              </li>
              <li>
                <Typography>
                  Przysługuje Pani/Panu prawo do żądania dostępu do swoich
                  danych osobowych, ich sprostowania, usunięcia lub ograniczenia
                  ich przetwarzania. Ma Pani/Pan prawo wniesienia skargi
                  dotyczącej przetwarzania Pani/Pana danych osobowych do organu
                  nadzorczego. Pani/Pana dane osobowe nie będą wykorzystywane do
                  zautomatyzowanego podejmowania decyzji wywołujących wobec
                  Pani/Pana skutki prawne.
                </Typography>
              </li>
            </ol>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MobilePrivacyPolcy;
