import React from "react";
import { Grid, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet"
import API from "../../API/API";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Formik } from 'formik';
import * as Yup from "yup";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginBottom: "10px",
    },
    error: {
        color: "red",
    },
    name: {
        width: "100%",
    },
    lastName: {
        width: "100%"
    },
    phoneNumber: {
        width: "100%"
    },
    successButton: {
        width: "100%",
        background:"#4caf50 !important",
        color:"white !important",
    },
    email: {
        width: "100%",
    },
    message: {
        width: "100%"
    },
    sendButton: {
        width: "100%"
    },
    rowGap: {
        paddingBottom: "15px",
    },
    heading: {
        fontSize: theme.typography.pxToRem(22),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.primary,
    },
    align: {
        alignItems: "center",
        alignContent: "center"
    },
    column: {
        flexBasis: "33.33%",
    },
    columnExtended: {
        flexBasis: "60.00%",
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 1),
    },
}));
function Register() {
    const classes = useStyles();
    const phoneRegExp = /(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/

    const sendMail = (values) => {
        API.post("SendMail.php", values).then((res) => {
        }).catch((error) => {
        });

    };
    const [isSent, setIsSent] = React.useState({
        sent:false
    });
    const handleSent = () => {
        setIsSent({sent: true});
    };
    return (
        <Grid container style={
            {
            paddingTop: "50px", paddingBottom: "50px",
        paddingLeft: "5px",
        paddingRight: "5px"}}>
            <Helmet>
                <title>Centrum Medyczne FemiMed - Rejestracja</title>
                <meta name="description" content="Centrum Medyczne FemiMed w Sosnowcu. Rejestracja." />
            </Helmet>
            <Grid item xs={1} />

            <Grid
                container
                item
                xs={10}
                style={{
                    backgroundColor: "rgba(255,255,255,0.8)",
                    paddingTop: "50px",
                    paddingBottom: "50px",
                }}
                justify="center"
                component={Paper}
                direction={"column"}
            >
                <Grid item xs>
                    <Typography variant={"h3"} component={"h2"} align={"center"} style={{ fontWeight: "500" }}>
                        Rejestracja
                    </Typography>
                </Grid>

                <Grid item container xs style={{ paddingTop: "50px" }}>

                    <Grid item xs={2} />
                    <Grid item xs={8} >
                        <Formik
                            initialValues={{ email: "", name: "", message: "", phoneNumber: "" }}
                            onSubmit={async values => {
                                handleSent();
                                sendMail(values);
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email().required("Wymagane"),
                                phoneNumber: Yup.string().matches(phoneRegExp, 'Numer telefonu jest błędny'),
                                name: Yup.string().required("Wymagane").nullable(false),
                                message: Yup.string().required("Wymagane").nullable(false),
                            })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    isValid,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    dirty
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>

                                        <Grid item xs={12} className={classes.rowGap}>
                                            <TextField
                                            required
                                                label="Email"
                                                variant="outlined"
                                                id="email"
                                                type="text"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={classes.email}
                                                helperText={errors.email && touched.email && (<Typography component={'span'} className={classes.error}>Błędny Adres Email</Typography>)}
                                            />

                                        </Grid>
                                        <Grid item xs={12} className={classes.rowGap}>
                                        <TextField
                                    className={classes.phoneNumber}
                                        id="phoneNumber"
                                        label="Numer Telefonu"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                        helperText={errors.phoneNumber && touched.phoneNumber && (<Typography component={'span'} className={classes.error}>Błędny Numer Telefonu</Typography>)}

                                    />
                                        </Grid>
                                        <Grid item xs={12} className={classes.rowGap}>

                                            <TextField
                                                className={classes.name}
                                                id="name"
                                                label="Imię i Nazwisko"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.rowGap}>
                                            <TextField
                                                className={classes.message}
                                                multiline
                                                rows={6}
                                                id="message"
                                                label="Wiadomość"
                                                value={values.message}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                        {isSent.sent ?
                                            
                                            <Button className={classes.successButton} variant="contained"disabled={true}>
                                                Wysłane
                                            </Button> 
                                            :
                                            <Button className={classes.sendButton} variant="contained" color="primary" type="submit" disabled={!(isValid && dirty)}>
                                                Wyślij
                                            </Button>
                                            }
                                        </Grid>

                                    </form>
                                );
                            }}
                        </Formik>
                    </Grid>
                    <Grid item xs={2} />
                </Grid>
            </Grid>
            <Grid item xs={1} />
        </Grid>
    );
}

export default Register;
