import React from "react";
import { Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet"

function PrenatalTesting() {
  const useStyle = {
    paddingLeft: "15px",
  };
  return (
    <Grid
      container
      style={{
        paddingTop: "50px",
        paddingBottom: "50px",
        paddingLeft: "5px",
        paddingRight: "5px",
      }}
    >
      <Helmet>
        <title>
        Centrum Medyczne FemiMed - Badania prenatalne
        </title>
        <meta name="description" content="Centrum Medyczne FemiMed w Sosnowcu rekomenduje wraz z Polskim Towarzystwem Ginekologów i Położników, wykonanie przyjanmniej 3 badań USG podczas ciąży, 
        w każdym trymetrze po jednym, o ile nie ma przesłanek do częstszego wykonywania oceny płodu."/>
      </Helmet>
      <Grid
        container
        item
        xs={12}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "25px",
          paddingBottom: "25px",
        }}
        justify="center"
        component={Paper}
        direction={"column"}
      >
        <Grid item xs>
          <Typography variant={"h4"} component={"h2"} align={"center"} style={{fontWeight:"500"}}>
            Badania Prenatalne
          </Typography>
        </Grid>

        <Grid item container xs style={{ paddingTop: "25px" }}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant={"body1"} align={"justify"}>
              Według Rekomendacji Polskiego Towarzystwa Ginekologów i
              Położników, podczas ciąży należy wykonać przynajmniej 3 badania
              USG, w każdym trymetrze po jednym, o ile nie ma przesłanek do
              częstszego wykonywania oceny płodu. Istnieją zatem 3 badania
              prenatalne:
            </Typography>
            <Typography variant={"body1"} style={{ paddingTop: "15px" }}>
              <b>
                I badanie prenatalne wykonywane jest między 11+0 a 13+6 tyg
                ciąży:
              </b>
            </Typography>
            <Typography
              variant={"body1"}
              style={{ paddingTop: "15px" }}
            >
              Służy do
              <li style={useStyle}>
                wstępnej oceny anatomii rozwojowej płodu , w celu wykluczenia
                dużych wad
              </li>
              <li style={useStyle}>
                oceny ryzyka wystąpienia najczęstszych aberracji chromosomowych
                (trisomii 21, 18, 13).
              </li>
              Kalkulacja ryzyka (wyliczenie prawdopodobieństwa wystąpienia
              choroby genetycznej płodu) jest oparta o wywiad, wiek matki, ocenę
              markerów ultrasonograficznych i biochemicznych (test podwójny lub
              test PAPP-A) i powinna odbywać się wyłącznie przy użyciu
              certyfikowanych przez FMF (Fetal Medicine Foundation)
              kalkulatorów.
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingTop: "15px" }}
              align={"justify"}
            >
              <b>
                II badanie prenatalne wykonywane jest między 18+0 – 22+6 tyg
                ciąży:
              </b>
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingTop: "15px" }}
            >
              Służy do
              <li style={useStyle}>
                szczegółowej oceny narządów płodu pod kątem występowania wad
                wrodzonych (ocena budowy anatomicznej płodu).
              </li>
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingTop: "15px" }}
              align={"justify"}
            >
              <b>
                III badanie prenatalne wykonywane jest między 28+0 – 32+6 tyg
                ciąży:
              </b>
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingTop: "15px" }}
            >
              Służy do
              <li style={useStyle}>
                oceny wzrastania płodu oraz ewentualnie oceny dobrostanu płodu w
                wybranych sytuacjach klinicznych (nadciśnienie u matki,
                cukrzyca, padaczka i inne przewlekle choroby matki)
              </li>
              <li style={useStyle}>oceny anatomii rozwojowej płodu.</li>
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingTop: "15px" }}
              align={"justify"}
            >
              W trosce o bezpieczeństwo oraz najwyższą jakość wykonywanych
              świadczeń – badanie ultrasonograficzne powinno być wykonywane
              przez osoby posiadające stosowne uprawnienia i kwalifikacje,
              potwierdzone odpowiednimi dokumentami wydawanymi przez organizacje
              krajowe oraz międzynarodowe oraz poddających swe wyniki okresowej
              kontroli i audytowi.
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingTop: "15px" }}
              align={"justify"}
            >
              Badania prenatalne muszą być wykonywane na odpowiednich,
              spełniających kryteria PTGiP, FMF, aparatach ultrasonograficznych.
              My pracujemy na najlepszym sprzęcie: aparat Voluson E8 BT 18 z
              możliwością obrazowania (oprócz 2D),3D/4D,HD Live, z możliwością
              rejestracji badania na nośniku CD lub pendrive).
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingTop: "15px" }}
              align={"justify"}
            >
              <b>Test wolnego płodowego DNA</b>
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingTop: "15px" }}
              align={"justify"}
            >
              Test wolnego płodowego DNA – podobnie jak test złożony, określa
              ryzyko (prawdopodobieństwo) wystąpienia aneuplodii (tj. zaburzeń
              materiału genetycznego) poprzez detekcję materiału genetycznego
              płodu, tzw. cffDNA krążącego we krwi obwodowej matki.
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingTop: "15px" }}
              align={"justify"}
            >
              Badanie jest NIEINWAZYJNE, tzn. nie niesie ze sobą ryzyka
              poronienia. Polega na pobraniu krwi matki, w której DNA płodowe
              zostaje poddane analizie metodą Sekwencjonowania Nowej Generacji
              (NGS).
            </Typography>
            <Typography
              variant="body1"
              style={{ paddingTop: "15px" }}
              align={"justify"}
            >
              Testy płodowego DNA charakteryzują się bardzo dużą czułością oraz
              dokładnością powyżej 99%, można je wykonać już po 10. tygodniu
              ciąży.
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PrenatalTesting;
