import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import NavBar from "../component/desktop/bars/NavBar";
import MainPage from "../component/desktop/content/MainPage";
import Contact from "../component/desktop/content/Contact";
import Footer from "../component/desktop/bars/Footer";
import Prices from "../component/desktop/content/Prices";
import Team from "../component/desktop/content/Team";
import ScrollToTop from "../component/util/ScrollToTop";
import Prenatal from "../component/desktop/content/PrenatalTesting";
import Gynecology from "../component/desktop/content/Gynecology";
import Obstetrics from "../component/desktop/content/Obstetrics";
import Perinatological from "../component/desktop/content/Perinatological";
import Ktg from "../component/desktop/content/Ktg";
import Usg from "../component/desktop/content/Usg";
import LaboratoryDiagnostics from "../component/desktop/content/LaboratoryDiagnostics";
import TeamMember from "../component/desktop/content/TeamMember"
import MobileLaboratoryDiagnostics from "../component/mobile/content/LaboratoryDiagnostics";
import './../App.css';
import MobileNavBar from "../component/mobile/bars/NavBar";
import MobileMainPage from "../component/mobile/content/MainPage";
import MobileContact from "../component/mobile/content/Contact";
import MobileFooter from "../component/mobile/bars/Footer";
import MobilePrices from "../component/mobile/content/Prices";
import MobileTeam from "../component/mobile/content/Team";
import MobilePrenatal from "../component/mobile/content/PrenatalTesting";
import MobileGynecology from "../component/mobile/content/Gynecology";
import MobileObstetrics from "../component/mobile/content/Obstetrics";
import MobilePerinatological from "../component/mobile/content/Perinatological";
import MobileKtg from "../component/mobile/content/Ktg";
import MobileUsg from "../component/mobile/content/Usg";
import PrivacyPolicy from '../component/desktop/content/PrivacyPolicy';
import MobilePrivacyPolicy from '../component/mobile/content/PrivacyPolicy';
import MobileTeamMember from '../component/mobile/content/TeamMember';
import MobileRegister from '../component/mobile/content/Register';

import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import Register from "../component/desktop/content/Register";
function App(props) {
  let content;

  if (isWidthUp('lg', props.width)) {
    content = <Switch>
    <React.Fragment>
      
      <div style={{ position: "relative", minHeight: "100vh" }}>
        <NavBar>
          <Route exact path={"/"} component={MainPage} />
          <Route exact path={"/kontakt"} component={Contact} />
          {/* <Route exact path={"/cennik"} component={Prices} /> */}
          <Route exact path={"/badania-prenatalne"} component={Prenatal} />
          <Route exact path={"/ginekologia"} component={Gynecology} />
          <Route exact path={"/poloznictwo"} component={Obstetrics} />
          <Route exact path={"/diagnostyka-usg"} component={Usg} />
          <Route exact path={"/rejestracja"} component={Register} />
          {/*<Route exact path={"/medycyna-estetyczna"} component={AestheticMed} />*/}

          <Route
            exact
            path={"/konsultacje-perinatologiczne"}
            component={Perinatological}
          />
          <Route exact path={"/ktg"} component={Ktg} />
          <Route
            exact
            path={"/diagnostyka-laboratoryjna"}
            component={LaboratoryDiagnostics}
          />
          <Route exact path={"/zespol"} component={Team} />
          <Route exact path={`/zespol/:memberName`} component={TeamMember}/>
          <Route exact path={"/polityka-prywatnosci"} component={PrivacyPolicy} />

        </NavBar>

        <Footer />
      </div>
    </React.Fragment>
    <Route component={MainPage} />
  </Switch>
  }
  else if(isWidthDown('lg', props.width)){
    content=<Switch>
    <React.Fragment>
      
      <div style={{ position: "relative", minHeight: "100vh" }}>
        <MobileNavBar>

        <Route exact path={"/"} component={MobileMainPage} />
          <Route exact path={"/kontakt"} component={MobileContact} />
          {/* <Route exact path={"/cennik"} component={MobilePrices} /> */}
          <Route exact path={"/badania-prenatalne"} component={MobilePrenatal} />
          <Route exact path={"/ginekologia"} component={MobileGynecology} />
          <Route exact path={"/poloznictwo"} component={MobileObstetrics} />
          <Route exact path={"/diagnostyka-usg"} component={MobileUsg} />
          <Route exact path={"/rejestracja"} component={MobileRegister} />

          {/*<Route exact path={"/medycyna-estetyczna"} component={MobileAestheticMed} />*/}
          <Route
            exact
            path={"/konsultacje-perinatologiczne"}
            component={MobilePerinatological}
          />
          <Route exact path={"/ktg"} component={MobileKtg} />
          <Route
            exact
            path={"/diagnostyka-laboratoryjna"}
            component={MobileLaboratoryDiagnostics}
          />
          <Route exact path={"/zespol"} component={MobileTeam} />
          <Route exact path={`/zespol/:memberName`} component={MobileTeamMember}/>

          <Route exact path={"/polityka-prywatnosci"} component={MobilePrivacyPolicy} />

        </MobileNavBar>

        <MobileFooter />
      </div>
    </React.Fragment>
    <Route component={MobileMainPage} />
  </Switch>
    
  }

  return (
    <BrowserRouter basename={"/"}>
      <ScrollToTop />
      {content}
    </BrowserRouter>
  );
}

export default withWidth()(App);
