import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import MailIcon from "@material-ui/icons/Mail";
import Info from './../../util/Info.json'

const useStyles = makeStyles((theme) => ({
  footer: {
    // marginTop:"1rem",
    padding: "1rem",
    paddingTop: "2rem",
    backgroundColor: "#2b9c96",
    color: "white",
    position: "absolute",
    zIndex: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "20rem",

    // borderRadius:""
    // marginTop:'calc(5% - 60px)',
    // bottom:0,
    // backgroundColor:"black",
    // // position:"sticky"
  },
}));

function Footer() {
  const styles = useStyles();

  return (
    <Grid container className={styles.footer}>
      <Grid item xs={2} />
      <Grid item xs={2}>
        <Typography variant={"h5"} component={"h4"} noWrap style={{ marginBottom: "10px" }}>
          Godziny Otwarcia
        </Typography>
        <Typography variant={"body1"}>
          <b>Pon - Pt :</b> {Info.timeStart} - {Info.timeStop}
        </Typography>
        <Typography variant={"body1"}>{Info.closed}: Nieczynne</Typography>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={2}>
        <Typography variant={"h5"} style={{ marginBottom: "10px" }}>
          Kontakt
        </Typography>
        <Typography
          variant={"body1"}
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <PhoneAndroidIcon style={{marginRight:"5px"}}/>
          {Info.mobilePhone}
        </Typography>
        <Typography
          variant={"body1"}
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <PhoneIcon style={{marginRight:"5px"}}/>
          {Info.stationPhone}
        </Typography>

        <Typography
          variant={"body1"}
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <MailIcon style={{marginRight:"5px"}}/>
          {Info.email}
        </Typography>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={2}>
        <Typography variant={"h5"} style={{ marginBottom: "10px" }}>
          Informacje
        </Typography>
        <Typography variant={"body2"} style={{marginBottom:"10px"}}>
        Nasza strona internetowa korzysta z plików cookies w celu dostosowania
        ustawień oraz zbierania anonimowych danych statystycznych. Za pomocą
        ustawień swojej przeglądarki możesz określić warunki przechowywania
        cookies.
        </Typography>
         
        <Link to="/polityka-prywatnosci" style={{color:"white"}}>
        Polityka Prywatności
        </Link>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
}

export default Footer;
