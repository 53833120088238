import React from "react";
import { Grid, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FemiMedPhoto from "../../../img/femiMed_zdjecie_glowne.webp";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import ImgUSG from "../../../img/gallery/fullsize/webp/femimed_aparat_usg.webp";
import ImgTUSG from "../../../img/gallery/thumbnails/webp/femimed_aparat_usg_thumbnail.webp";
import ImgGab from "../../../img/gallery/fullsize/webp/femimed_gabinet_1.webp";
import ImgTGab from "../../../img/gallery/thumbnails/webp/femimed_gabinet_1_thumbnail.webp";
import ImgGab2 from "../../../img/gallery/fullsize/webp/femimed_gabinet_2.webp";
import ImgTGab2 from "../../../img/gallery/thumbnails/webp/femimed_gabinet_2_thumbnail.webp";
import ImgCorr from "../../../img/gallery/fullsize/webp/femimed_korytarz_2.webp";
import ImgTCorr from "../../../img/gallery/thumbnails/webp/femimed_korytarz_2_thumbnail.webp";
import ImgGab3 from "../../../img/gallery/fullsize/webp/femimed_gabinet_3.webp";
import ImgTGab3 from "../../../img/gallery/thumbnails/webp/femimed_gabinet_3_thumbnail.webp";
import ImgLobby from "../../../img/gallery/fullsize/webp/femimed_poczekalnia.webp";
import ImgTLobby from "../../../img/gallery/thumbnails/webp/femimed_poczekalnia_thumbnail.webp";
import ImgRoom from "../../../img/gallery/fullsize/webp/femimed_pokoj_przygotowawczy_1.webp";
import ImgTRoom from "../../../img/gallery/thumbnails/webp/femimed_pokoj_przygotowawczy_1_thumbnail.webp";
import ImgRec from "../../../img/gallery/fullsize/webp/femimed_recepcja.webp";
import ImgTRec from "../../../img/gallery/thumbnails/webp/femimed_recepcja_thumbnail.webp";
import {Helmet} from "react-helmet"
import nl2br from 'react-newline-to-break';


function MainPage() {
  // const options = {
  //   history:false
  // }
  // const neutralizeBack = callback => {
  //   window.history.pushState(null, "", window.location.href);
  //   window.onpopstate = () => {
  //     window.history.pushState(null, "", window.location.href);
  //     callback();
  //   };
  // };
  // const revivalBack = () => {
  //   window.onpopstate = undefined;
  //   window.history.back();
  // };

//   const handleOpenModal = () =>
//   this.setState(
//     { open: true },
//     () => neutralizeBack(this.handleCloseModal)
//   );

//  const handleCloseModal = () =>
//   this.setState(
//     { open: false },
//     revivalBack
//   );

const higherItemStyle = {
  cursor: "pointer",
  objectFit: "cover",
  width: "100%",
  height: "100%",
};

const widerItemStyle = {
  cursor: "pointer",
  objectFit: "cover",
  width: "100%",
  height: "100%",
};

  return (
    <Grid
      container
      style={{
        paddingTop: "50px",
        paddingBottom: "50px",
        paddingLeft: "5px",
        paddingRight: "5px",
      }}
    >
      <Helmet>
        <title>
        Centrum Medyczne FemiMed - Strona główna
        </title>
        <meta name="description" content="Centrum Medyczne FemiMed w Sosnowcu oferuje najwyższe
    standardy obsługi medycznej, profesjonalizm oraz nowoczesne wyposażenie. Zapewnia opiekę kobietom oraz
    ciężarnym przez wysoko wykwalifikowaną kadrę medyczną"/>
      </Helmet>
      <Grid
        container
        item
        xs={12}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "25px",
          paddingBottom: "25px",
        }}
        justify="center"
        component={Paper}
        alignItems={"center"}
        direction={"column"}
      >
        <Grid item xs>
          <Typography variant={"h4"} component={"h2"} align={"center"} style={{fontWeight:"500"}}>
            Centrum Medyczne FemiMed w Sosnowcu
          </Typography>
        </Grid>

        <Grid
          item
          xs
          // style={{width:"75%"}}
        >
        </Grid>
        <Grid
          item
          container
          alignItems={"center"}
          justify={"center"}
          xs
          direction={"row"}
          style={{ paddingTop: "50px" }}

        >
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Grid
              item
              container
              alignItems={"center"}
              justify={"center"}
              direction={"column"}
            >
              <img
                src={FemiMedPhoto}
                style={{
                  borderRadius: "25px 25px 25px 25px",
                  minHeight: "40%",
                  minWidth: "40%",
                  maxHeight: "75%",
                  maxWidth: "75%",
                }}
                alt={"Klinika FemiMed w Sosnowcu"}
              />
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container item xs style={{ paddingTop: "50px" }}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant={"body1"} align={"justify"} paragraph={true}>
              Zapraszamy do <b>Centrum Medycznego FemiMed</b> gdzie oferujemy najwyższe
              standardy obsługi medycznej, profesjonalizm oraz nowoczesne
              wyposażenie wnętrz i gabinetów. Zapewniamy opiekę kobietom oraz
              ciężarnym przez wysoko wykwalifikowaną kadrę medyczną, z
              wieloletnim stażem i doświadczeniem klinicznym. Zapewniamy
              ciągłość opieki od diagnozy - poprzez wykonanie badań
              diagnostycznych dodatkowych, w tym badań USG, laboratoryjnych, po
              leczenie ambulatoryjne i szpitalne. W FemiMed przeprowadzane są
              konsultacje z zakresu:
            </Typography>
            <Typography
              variant={"body1"}
              style={{ paddingLeft: "25px" }}
              paragraph={true}
            >
              <li>Ginekologii</li>
              <li>Położnictwa</li>
              <li>Perinatologii</li>
              <li>Diagnostyki prenatalnej</li>
              <li>{nl2br("Badań ultrasonograficznych, \n w tym sutka.")}</li>
            </Typography>
            <Typography variant={"body1"} align={"justify"} paragraph={true}>
              Nieustająco doskonaląca się kadra medyczna, najnowocześniejszy
              sprzęt, zapewnia najwyższy standard opieki i skuteczne leczenie
              pacjenta.
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container item xs>
          <Grid item xs={12} direction={"column"} container alignItems={"center"} justify={"center"}>
            <Gallery id="1" options={{history:true}}>
            <div
                style={{
                  display: "grid",
                  // gridTemplateColumns: '240px 171px 171px 171px',
                  gridTemplateColumns: "135px 135px",

                  // gridTemplateColumns: "1fr 1fr 1fr",
                  // gridTemplateRows: '114px 114px',
                  // gridTemplateRows: "1fr 1fr",
                  gridTemplateRows: "180px 180px",

                  gridGap: 12,
                }}
              >
                <Item
                  original={ImgUSG}
                  thumbnail={ImgTUSG}
                  width="1200"
                  height="1600"
                  // title=""
                  id="2"
                >
                  {({ ref, open }) => (
                    <img
                      style={higherItemStyle}
                      // style={{
                      //     cursor: 'pointer', smallItemStyles
                      //     // borderRadius:"30px 30px 30px 30px"
                      // }}
                      src={ImgTUSG}
                      ref={ref}
                      onClick={open}
                      alt={"Aparat USG używany w Femimed"}
                    />
                  )}
                </Item>
                <Item
                  original={ImgGab}
                  thumbnail={ImgTGab}
                  width="1200"
                  height="1600"
                  id="3"
                >
                  {({ ref, open }) => (
                    <img
                      style={higherItemStyle}
                      src={ImgTGab}
                      ref={ref}
                      onClick={open}
                      alt={"Wnętrze gabinetu w centrum medycznym Femimed"}
                    />
                  )}
                </Item>
                <Item
                  original={ImgGab2}
                  thumbnail={ImgTGab2}
                  width="1200"
                  height="1600"
                  id="4"
                >
                  {({ ref, open }) => (
                    <img
                      style={higherItemStyle}
                      src={ImgTGab2}
                      ref={ref}
                      onClick={open}
                      alt={"Wnętrze gabinetu w centrum medycznym Femimed"}
                    />
                  )}
                </Item>

                <Item
                  original={ImgCorr}
                  thumbnail={ImgTCorr}
                  width="1200"
                  height="1600"
                  id="5"
                >
                  {({ ref, open }) => (
                    <img
                      style={higherItemStyle}
                      src={ImgTCorr}
                      ref={ref}
                      onClick={open}
                      alt={"Główny korytarz w centrum medycznym Femimed"}
                    />
                  )}
                </Item>
                </div>
                <div
                style={{
                  marginTop:"10px",
                  display: "grid",
                  // gridTemplateColumns: '240px 171px 171px 171px',
                  gridTemplateColumns: "140px 140px",

                  // gridTemplateColumns: "1fr 1fr 1fr",
                  // gridTemplateRows: '114px 114px',
                  // gridTemplateRows: "1fr 1fr",
                  gridTemplateRows: "105px 105px",

                  gridGap: 12,
                }}
              >

                <Item
                  original={ImgGab3}
                  thumbnail={ImgTGab3}
                  width="1600"
                  height="1200"
                  id="6"
                >
                  {({ ref, open }) => (
                    <img
                      style={widerItemStyle}
                      src={ImgTGab3}
                      ref={ref}
                      onClick={open}
                      alt={"Wnętrze gabinetu w centrum medycznym Femimed"}
                    />
                  )}
                </Item>
                <Item
                  original={ImgLobby}
                  thumbnail={ImgTLobby}
                  width="1600"
                  height="1200"
                  id="7"
                >
                  {({ ref, open }) => (
                    <img
                      style={widerItemStyle}
                      src={ImgTLobby}
                      ref={ref}
                      onClick={open}
                      alt={"Poczekalnia w centrum medycznym Femimed"}
                    />
                  )}
                </Item>
                <Item
                  original={ImgRoom}
                  thumbnail={ImgTRoom}
                  width="1600"
                  height="1200"
                  id="8"
                >
                  {({ ref, open }) => (
                    <img
                      style={widerItemStyle}
                      src={ImgTRoom}
                      ref={ref}
                      onClick={open}
                      alt={"Pokój przygotowywawczy w centrum medycznym Femimed"}
                    />
                  )}
                </Item>
                <Item
                  original={ImgRec}
                  thumbnail={ImgTRec}
                  width="1600"
                  height="1200"
                  id="9"
                >
                  {({ ref, open }) => (
                    <img
                      style={widerItemStyle}
                      src={ImgTRec}
                      ref={ref}
                      onClick={open}
                      alt={"Recepcja w centrum medycznym Femimed"}
                    />
                  )}
                </Item>
                
              </div>
            </Gallery>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MainPage;
