import React, { useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";
import PriceListJSON from "./../../util/Prices.json";

function Prices() {
  const [PriceList] = useState(PriceListJSON);

  const priceStyle = {
    borderBottom:"1px solid black", 
    paddingTop:"15px",
  };
  const singlePriceStyle={
    fontWeight:"500",
    fontSize:"20px"
  }
  return (
    <Grid container>
      <Helmet>
        <title>Centrum Medyczne FemiMed - Cennik</title>
        <meta name="description" content="Centrum FemiMed w Sosnowcu oferuje usługi najwyższej klasy w przystępnych cenach."/>
      </Helmet>
      <Grid item xs={1} />

      <Grid
        container
        item
        xs={10}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "50px",
          paddingBottom: "25px",
        }}
        justify="center"
        component={Paper}
        direction={"column"}
      >
        {/* <Grid item xs>
          <Typography variant={"h3"} component={"h2"} align={"center"}>
            Cennik usług medycznych
          </Typography>
        </Grid>

        <Grid item xs style={{paddingTop:"50px"}} >
          <Typography variant={"body1"} align={"center"}>
            //!TODO Text
          </Typography>
        </Grid> */}
        <div>
        {PriceList.medicalService.map((item, key) => (
          <div key={key} style={{paddingBottom:"50px"}}>
            <Grid item xs key={"grid"+key}>
              <Typography key={"title"+key} variant={"h3"} component={"h2"} align={"center"} style={{fontWeight:"500"}}>
                {item.title}
              </Typography>
            </Grid>
            
            <Grid container key={"innerMainGrid"+key} item xs={12} style={{ paddingTop: "25px" }}>
              <Grid item key={"innerLeftSideGrid"+key} xs={2}/>

              <Grid item key={"innerContentGrid"+key} xs={8}>

                  {item.subtitle != null ?
                      <Typography key={"title"+key} variant={"h4"} component={"h3"} align={"center"} style={{fontWeight:"500"}}>
                          {item.subtitle}
                      </Typography>

                  :null}
            {item.type.map((price,innerKey)=>(
              
                <Grid item container xs={12} key={"namePriceGrid"+innerKey} direction="row"   justify="space-between" style={priceStyle}
                >
                  <Grid item container xs={8} direction={"column"}  alignItems="flex-start"   justify="center"
>
                <Typography key={"priceName"+innerKey} style={singlePriceStyle}>
                {price.name}
              </Typography>
              {price.comment ? <Typography key={"priceComment"+innerKey} style={{fontSize:"14px"}}> {price.comment}</Typography>: null}
              </Grid>
              <Grid item container xs={4}  direction={"column"}  alignItems="flex-end"   justify="center"
>

              <Typography key={"price"+innerKey} style={singlePriceStyle}>
                {price.price}
              </Typography>
              </Grid>

              </Grid>
                          
            
            ))}
            </Grid>
            </Grid>
            <Grid item xs={2}/>
          </div>
        ))}
        </div>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}

export default Prices;
