import React from "react";
import {Grid, Paper} from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import HorizontalLine from "../../util/HorizontalLine";
import Doctor from "../../util/DoctorMobile";
import {Helmet} from "react-helmet";
import TeamList from "./../../util/Team.json"

function Team() {
    return (
        <Grid container style={{
            paddingTop: "50px", paddingBottom: "50px",
            paddingLeft: "5px",
            paddingRight: "5px"
        }}>
            <Helmet>
                <title>
                    Centrum Medyczne FemiMed - Zespół
                </title>
                <meta name="description" content="W Centrum Medycznym FemiMed w Sosnowcu przyjmują m.in :
        Bogusława Piela - Ginekolog.
        Krzysztof Kansy - Radiolog.
        Marzena Malec - Ginekolog.
        Kamila Kansy - Estetyka.
        "/>
            </Helmet>

            <Grid
                container
                item
                xs={12}
                style={{
                    backgroundColor: "rgba(255,255,255,0.8)",
                    paddingTop: "25px",
                    paddingBottom: "25px",
                }}
                component={Paper}
                alignItems={"center"}
                direction={"column"}
            >
                <Grid item xs>
                    <Typography variant={"h4"} component={"h2"} align={"center"} style={{fontWeight: "500"}}>
                        Lekarze Specjaliści Centrum Medycznego
                    </Typography>
                    <Typography variant={"h4"} component={"h2"} align={"center"} style={{fontWeight: "500"}}>
                        FemiMed w Sosnowcu
                    </Typography>
                </Grid>
                <Grid item xs>
                    <HorizontalLine/>
                </Grid>


                <Grid item xs>
                    <HorizontalLine/>
                </Grid>
                <Grid container>
                    <Grid item xs={1}/>
                    <Grid item container xs={10}>
                        {TeamList.doctor.map((doc, key) => (
                            <Grid key={key} item xs style={{padding: "10px"}}>
                                <Doctor
                                    key={key}
                                    id={doc.id}
                                    alt={doc.alt}
                                    image={doc.image}
                                    title={doc.title}
                                    name={doc.name}
                                    spec1={doc.spec1}
                                    spec2={doc.spec2}
                                    spec3={doc.spec3}
                                    spec4={doc.spec4}
                                    description1={doc.description1}
                                    description2={doc.description2}
                                    description3={doc.description3}
                                    description4={doc.description4}
                                    description5={doc.description5}
                                    description6={doc.description6}
                                    linkTo={doc.linkTo}
                                />
                            </Grid>

                        ))}
                    </Grid>

                    <Grid item xs={1}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Team;
