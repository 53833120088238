import React from "react";
import { Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";

function Gynecology() {
  return (
    <Grid container>
      <Helmet>
        <title>Centrum Medyczne FemiMed - Ginekologia</title>
        <meta
          name="description"
          content="Specjaliści w Centrum Medycznym FemiMed w Sosnowcu zapewniają 
          profilaktykę z zakresu Ginekologii i Położnictwa oraz diagnostykę i leczenie problemów kobiet na każdym etapie życia od pokwitania do menopauzy proponując różnorodny zakres usług"
        />
      </Helmet>
      <Grid item xs={1} />

      <Grid
        container
        item
        xs={10}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        justify="center"
        component={Paper}
        direction={"column"}
      >
        <Grid item xs>
          <Typography variant={"h3"} component={"h2"} align={"center"} style={{fontWeight:"500"}}>
            Ginekologia
          </Typography>
        </Grid>

        <Grid item container xs style={{ paddingTop: "50px" }}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Typography variant={"body1"} align={"justify"}>
              Specjaliści FemiMed z zakresu Ginekologii i Położnictwa zapewniają
              profilaktykę oraz diagnostykę i leczenie problemów kobiet na
              każdym etapie życia-od pokwitania do menopauzy-proponując
              różnorodny zakres usług:
            </Typography>
            <Typography
              variant={"body1"}
              style={{ paddingTop: "15px", paddingLeft: "25px" }}
              align={"justify"}
            >
              <li>Posiewy bakteriologiczne z narządu rodnego</li>
              <li>Badanie ultrasonograficzne przezpochwowe (TVS)</li>
              <li>
                Dobór antykoncepcji hormonalnej (tabletka antykoncepcyjna,
                wkładki wewnątrzmaciczne, w tym zawierające hormon, implant
                antykoncepcyjny podskórny)
              </li>
              <li>
                Wymazy cytologiczne w tym cienkowarstwowa cytologia na podłożu
                płynnym LBC (metoda dokładniejsza i bardziej czuła w stosunku do
                tradycyjnej cytologii)
              </li>
              <li>Leczenie zachowawcze kłykcin kończystych sromu</li>
              <li>
                Badanie w kierunku infekcji wirusem brodawczaka ludzkiego HPV
                (który jest odpowiedzialny za kłykciny kończyste oraz stany
                przednowotworowe i raka szyjki macicy)
              </li>
            </Typography>
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              <b>Podstawowa diagnostyka i leczenie niepłodności</b>
            </Typography>
            <Typography
              variant={"body1"}
              style={{ paddingLeft: "25px" }}
              paragraph={true}
            >
              <li>Poradnictwo przed-koncepcyjne</li>
              <li>Monitoring owulacji</li>
              <li>
                Wykonanie testu w kierunku zakażeń uroginekologicznych, w tym
                Chlamydią, Mycoplasmą, Ureaplasmą
              </li>
              <li>Stymulacja owulacji</li>
            </Typography>
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              <b>Endokrynologia ginekologiczna</b>
            </Typography>
            <Typography
              variant={"body1"}
              style={{ paddingLeft: "25px" }}
              paragraph={true}
            >
              <li>Diagnostyka i leczenie zaburzeń miesiączkowania</li>
              <li>Diagnostyka i leczenie zespołu policystycznych jajników</li>
              <li>Diagnostyka i leczenie nadmiernego owłosienia u kobiet</li>
              <li>Diagnostyka i leczenie hiperprolaktynemii</li>
            </Typography>
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              <b>Opieka nad kobietą w okresie menopauzalnym</b>
            </Typography>
            <Typography
              variant={"body1"}
              style={{ paddingLeft: "25px" }}
              paragraph={true}
            >
              <li>Badania hormonalne</li>
              <li>Dobór hormonalnej terapii zastępczej</li>
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}

export default Gynecology;
