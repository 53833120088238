import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import { CardContent, Grid } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(22),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  columnExtended: {
    flexBasis: "60.00%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 1),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    backgroundColor: "#2b9c96",
  },
}));

function Doctor(props) {
  const classes = useStyles();
  const images = require.context('../../img/portrait', true);
  const loadImage = imageName => (images(`./${imageName}`).default);
  return (
    <div className={classes.root}>
      <Card style={{ background: "none", border: "2px solid black" }}>
        <CardContent
          style={{ paddingBottom: "16px" }}
          // expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1c-content"
          id={props.id}
        >
          <Grid container alignItems={"center"}>
            <Grid xs={3} item>
              <div className={classes.column} style={{ paddingLeft: "45px" }}>
                <Avatar
                  src={props.image !== null ? loadImage(`${props.image}`): props.alt}
                  alt={props.alt}
                  className={classes.large}
                />
              </div>
            </Grid>
            <Grid item xs={7}>
              <div className={classes.columnExtended}>
                <Typography
                  align={"left"}
                  component="h2"
                  className={classes.heading}
                >
                  <b>{props.name}</b>
                </Typography>
                <Typography
                  align={"left"}
                  component="h3"
                  style={{ fontWeight: "500" }}
                  className={classes.secondaryHeading}
                >
                  {props.spec1}
                </Typography>
                <Typography
                  align={"left"}
                  component="h3"
                  style={{ fontWeight: "500" }}
                  className={classes.secondaryHeading}
                >
                  {props.spec2}
                </Typography>
                <Typography
                  align={"left"}
                  component="h3"
                  style={{ fontWeight: "500" }}
                  className={classes.secondaryHeading}
                >
                  {props.spec3}
                </Typography>
                <Typography
                  align={"left"}
                  component="h3"
                  style={{ fontWeight: "500" }}
                  className={classes.secondaryHeading}
                >
                  {props.spec4}
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              container
              xs={2}
              alignItems={"flex-end"}
              direction={"row"}
            >
              <CardActions disableSpacing>
                  <Link to={{pathname:"/zespol/"+props.linkTo}} style={{background:"#2b9c96", textDecoration:"none"}}>
                  <Button size="medium" style={{color:"white",}} >Czytaj Więcej</Button>
                  </Link>
              </CardActions>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default Doctor;
