import React from "react";
import { Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import HorizontalLine from "../../util/HorizontalLine";
import Doctor from "../../util/Doctor";
import {Helmet} from "react-helmet";
import TeamList from "./../../util/Team.json";


function Team() {
  return (
    <Grid container >
      <Helmet>
        <title>Centrum Medyczne FemiMed - Zespół</title>
        <meta name="description" content="W Centrum Medycznym FemiMed w Sosnowcu przyjmują m.in :
        Bogusława Piela - Ginekolog.
        Justyna Ferensowicz - Ginekolog.
        Krzysztof Kansy - Radiolog.
        Marzena Malec - Ginekolog.
        Kamila Kansy - Estetyka.
        "/>
      </Helmet>
      <Grid item xs={1} />

      <Grid
        container
        item
        xs={10}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        justify="center"
        component={Paper}
        alignItems={"center"}
        direction={"column"}
      >
        <Grid item xs>
          <Typography variant={"h3"} component={"h2"}  align={"center"} style={{fontWeight:"500"}}>
            Lekarze Specjaliści Centrum Medycznego
          </Typography>
          <Typography variant={"h3"} component={"h2"}  align={"center"} style={{fontWeight:"500"}}>
            FemiMed w Sosnowcu
          </Typography>
        </Grid>
        <Grid item xs>
          <HorizontalLine />
        </Grid>



        <Grid item xs>
          <HorizontalLine />
        </Grid>
        <Grid item container xs style={{paddingTop:"50px"}}>
          <Grid item xs={2} />
          <Grid item xs={8}>

          {TeamList.doctor.map((doc,key)=>(
              <Doctor
              key={key}
              id={doc.id}
              alt={doc.alt}
              image={doc.image}
              title={doc.title}
              name={doc.name}
              spec1={doc.spec1}
              spec2={doc.spec2}
              spec3={doc.spec3}
              spec4={doc.spec4}
              description1={doc.description1}
              description2={doc.description2}
              description3={doc.description3}
              description4={doc.description4}
              linkTo={doc.linkTo}
              description5={doc.description5}
              description6={doc.description6}
              />
            ))}
            {/* <Doctor
              id={"doc1"}
              alt={"BP"}
              image={"/static/images/avatar/1.jpg"}
              name={"Dr n. med. Bogusława Piela"}
              spec1={"Specjalista z dziedziny Położnictwa i Ginekologii"}
              spec2={"Specjalista z dziedziny Perinatologii "}
              description1={
                "Zastępca Ordynatora, Kierownik Oddziału Patologii Ciąży w Oddziale Klinicznym Perinatologii, Położnictwa i Ginekologii ŚUM w Rudzie Śląskiej."
              }
              description2={"Posiadane certyfikaty:"}
              description3={[
                "2014 – Certyfikat oceny serca płodu Sekcji Ultrasonograficznej Polskiego Towarzystwa Ginekologicznego ",
                "2014 – Certyfikat badań prenatalnych Sekcji Ultrasonograficznej Polskiego Towarzystwa Ginekologicznego",
                "2014 – Certyfikat badań dopplerowskich Sekcji Ultrasonograficznej Polskiego Towarzystwa Ginekologicznego ",
                "2010 – Certyfikat Sekcji Ultrasonograficznej Polskiego Towarzystwa Ginekologicznego na wykonywanie badań USG w Położnictwie i Ginekologii",
                "2010 – Certifikat The Fetal Medicine Foundation ukończenia Fetal Echocardiography Course ",
                "2007 – Certyfikat The Fetal Medicine Foundation na wykonywanie badań USG w ciąży pomiędzy 11-14 tygodniem",
              ]}
            />
            <Doctor
              id={"doc2"}
              alt={"JF"}
              image={"/static/images/avatar/1.jpg"}
              name={"Lek. med. Justyna Ferensowicz"}
              spec1={"Specjalista z dziedziny Położnictwa i Ginekologii"}
              description1={
                "Starszy asystent w Oddziale Klinicznym Perinatologii, Położnictwa i Ginekologii ŚUM w Rudzie Śląskiej."
              }
             
            />

            <Doctor
              id={"doc3"}
              alt={"AJ"}
              image={"/static/images/avatar/1.jpg"}
              name={"Lek. med. Agnieszka Jagielska"}
              spec1={"Specjalista z dziedziny Położnictwa i Ginekologii"}
              description1={
                "Asystent w Oddziale Ginekologii i Położnictwa , Centrum Zdrowia Kobiety i Dziecka w Zabrzu "
              }
            
            />
            <Doctor
              id={"doc4"}
              alt={"MM"}
              image={"/static/images/avatar/1.jpg"}
              name={"Lek. med. Marzena Malec"}
              spec1={"W trakcie specjalizacji z dziedziny Położnictwa i Ginekologii"}
              description1={
                "Młodszy asystent w Oddziale Klinicznym Perinatologii, Położnictwa i Ginekologii ŚUM w Rudzie Śląskiej"
              }
            
            />
            <Doctor
              id={"doc5"}
              alt={"KK"}
              image={"/static/images/avatar/1.jpg"}
              name={"Lek. med. Krzysztof Kansy"}
              spec1={"W trakcie specjalizacji z dziedziny Radiologii i Diagnostyki Obrazowej "}
              description1={
                "Rezydent w Zakładzie Radiologii Centrum Onkologii w Gliwicach"
              }
            
            /> */}
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}

export default Team;
