import React from "react";
import {Grid, Paper} from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet"

function LaboratoryDiagnostics() {
    return (
        <Grid container style={{
            paddingTop: "50px", paddingBottom: "50px",
            paddingLeft: "5px",
            paddingRight: "5px"
        }}>
            <Helmet>
                <title>Centrum Medyczne FemiMed - Diagnostyka laboratoryjna</title>
                <meta name="description"
                      content="Centrum Medyczne FemiMed w Sosnowcu Współpracuje z Laboratorium diagnostycznym Synevo. Na miejscu pobieramy krew, mocz do badań, wymazy bakteriologiczne, cytologiczne i histopatologiczne."/>
            </Helmet>
            <Grid
                container
                item
                xs={12}
                style={{
                    backgroundColor: "rgba(255,255,255,0.8)",
                    paddingTop: "25px",
                    paddingBottom: "25px",
                }}
                justify="center"
                component={Paper}
                direction={"column"}
            >
                <Grid item xs>
                    <Typography variant={"h4"} component={"h2"} align={"center"} style={{fontWeight: "500"}}>
                        Diagnostyka Laboratoryjna
                    </Typography>
                </Grid>

                <Grid item container xs style={{paddingTop: "15px"}}>
                    <Grid item xs={1}/>
                    <Grid item xs={10}>
                        <Typography variant={"body1"} align={"justify"} style={{paddingTop: "15px"}}>
                            W pomieszczeniach naszego Centrum Medycznego znajduje się punkt pobrań <a href={"https://sla.pl/"} target="_blank"
                                                                                                      rel="noopener noreferrer">Śląskich Laboratoriów
                            Analitycznych</a>, w którym dostępny jest pełny zakres badań laboratoryjnych, m.in.:
                        </Typography>
                        <Typography variant={"body1"}>
                            <ul>
                                <li>
                                    Badania podstawowe i immunochemiczne
                                </li>
                                <li>
                                    Markery nowotworowe
                                </li>
                                <li>
                                    Hormony
                                </li>
                                <li>
                                    Badania autoimmunologiczne
                                </li>
                                <li>
                                    Panele alergologiczne
                                </li>
                                <li>
                                    Wymazy mikrobiologiczne
                                </li>
                                <li>
                                    Badania genetyczne.
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant={"body1"} align={"justify"} paragraph={true}>
                            W asortymencie znajdują się również dedykowane pakiety badań, m.in.:
                        </Typography>
                        <Typography variant={"body1"}>
                            <ul>
                                <li>Ogólny</li>
                                <li>Tarczycowy</li>
                                <li>Witaminy D3</li>
                                <li>Kobiety 40+</li>
                                <li>Mężczyźni 40+</li>
                                <li>Sercowy</li>
                                <li>Dla aktywnych/sportowy.</li>
                            </ul>
                        </Typography>
                        <Typography variant={"body1"} align={"justify"} paragraph={true}>
                            Badania laboratoryjne wykonać można bez skierowania od lekarza i bez wcześniejszego umawiania się.
                            Gabinet czynny jest <b>od poniedziałku do piątku w godzinach: 6:00 – 13:30.</b>
                        </Typography>
                        <Typography variant={"body1"} align={"justify"} paragraph={true}>
                            Badania zakupić można wygodnie drogą elektroniczną w <a href={"https://sla.pl/"} target="_blank"
                                                                                    rel="noopener noreferrer">e-sklepie</a> laboratorium (wówczas czas na ich
                            realizację to aż 90 dni) lub na miejscu w punkcie pobrań. Istnieje możliwość płatności kartą.
                        </Typography>
                        <Typography variant={"body1"} align={"justify"} paragraph={true}>
                            Większość wyników badań dostępna jest jeszcze tego samego dnia na platformie on-line. Do logowania
                            uprawnia Karta Pacjenta wydawana podczas rejestracji.
                        </Typography>
                        <Typography variant={"body1"} align={"justify"} paragraph={true}>
                            W gabinecie czeka doświadczony personel medyczny, który doradzi przy wyborze odpowiednich
                            parametrów diagnostycznych oraz udzieli wyczerpujących informacji odnośnie dostępnej oferty
                            badań.
                        </Typography>
                        <Typography variant={"body1"} align={"center"} paragraph={true}>
                            <b>Zadbaj o zdrowie swoje i bliskich!<br/>

                                Zapraszamy na wykonanie ważnych, profilaktycznych badań krwi!</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LaboratoryDiagnostics;
