import React from "react";
import { Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet"


function Usg() {

  return (
    <Grid container >
      <Helmet>
        <title>Centrum Medyczne FemiMed - Diagnostyka USG</title>
        <meta name="description" content="Centrum FemiMed w Sosnowcu oferuje możliwość wykonania różnorodnych badań ultrasonograficznych, 
        w tym USG piersi na wysokiej jakości sprzęcie ultrasonograficznym."/>
      </Helmet>
      <Grid item xs={1} />

      <Grid
        container
        item
        xs={10}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        justify="center"
        component={Paper}
        direction={"column"}
      >
        <Grid item xs>
          <Typography variant={"h3"} component={"h2"}  align={"center"} style={{fontWeight:"500"}}>
            Diagnostyka USG
          </Typography>
        </Grid>

        <Grid item container xs style={{paddingTop:"50px"}}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Typography variant={"body1"} align={"justify"}>
              W Centrum FemiMed oferujemy możliwość wykonania różnorodnych badań
              ultrasonograficznych, w tym USG piersi na wysokiej jakości
              sprzęcie ultrasonograficznym.
            </Typography>
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              Z uwagi na częstość i powszechność zmian guzowatych sutka wśród
              kobiet (rak sutka zajmuje pierwsze miejsce wśród nowotworów
              złośliwych u kobiet), każdej ciężarnej należy zaproponować badanie
              USG piersi (po 35 roku życia obowiązkowo).
            </Typography>
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              Wszystkim pacjentkom proponujemy i uczymy jak samodzielnie badać
              piersi, gdyż tylko wczesne wykrycie zmiany, szybka diagnostyka i
              leczenie gwarantuje sukces.
            </Typography>
          
            
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              USG zalecane do kontroli piersi (rak piersi najczęstszy u kobiet) – badanie profilaktyczne co roku.
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}

export default Usg;
