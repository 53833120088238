import React from "react";
import { Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet"

function Perinatological() {
  return (
    <Grid container >
      <Helmet>
        <title>Centrum Medyczne FemiMed - Konsultacje perinatologiczne</title>
        <meta name="description" content="Centrum Medyczne FemiMed w sosnowcu oferuje konsultacje Perinatologiczne, Perinatologia zwana inaczej medycyną matczyno-płodowa, 
        to specjalność medyczna zajmującą się nie tylko ciężarną, ale i jej mającym narodzić się dzieckiem."/>
      </Helmet>
      <Grid item xs={1} />

      <Grid
        container
        item
        xs={10}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        justify="center"
        component={Paper}
        direction={"column"}
      >
        <Grid item xs>
          <Typography variant={"h3"} component={"h2"}  align={"center"} style={{fontWeight:"500"}}>
            Konsultacje Perinatologiczne
          </Typography>
        </Grid>

        <Grid item container xs style={{paddingTop:"50px"}}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Typography variant={"body1"} align={"justify"}>
              Perinatologia, inaczej medycyna matczyno-płodowa, to specjalność
              medyczna zajmującą się nie tylko ciężarną, ale i jej mającym
              narodzić się dzieckiem.
            </Typography>
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              Perinatologia zawiera w sobie elementy różnych dziedzin wiedzy
              medycznej: położnictwa, neonatologii, genetyki klinicznej ,
              chirurgii i kardiologii dziecięcej oraz innych specjalizacji.
            </Typography>
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              Perinatolog otacza więc opieką przedporodową ciężarne z największą
              patologią ciąży, musi posiadać umiejętność rozpoznawania, leczenia
              i monitorowania stanu matki i płodu z najbardziej zagrożonych
              ciąż.
            </Typography>
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              Specjaliści medycyny matczyno-płodowej na całym świecie, również w
              Polsce, oprócz poradnictwa, wykonują zaawansowane badania
              ultrasonograficzne płodu, w tym diagnostykę prenatalną
              nieinwazyjną , jak i inwazyjną (tj. biopsję kosmówki,
              amniocentezę, kordocentezę, terapię wewnątrzmaciczną płodu).
            </Typography>
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              W naszym Centrum Medycznym perinatologiem jest dr Bogusława Piela,
              kierująca na co dzień Odcinkiem Patologii Ciąży III stopnia
              Referencyjności w Oddziale Klinicznym Perinatologii, Położnictwa i
              Ginekologii w Rudzie Śląskiej, gdzie hospitalizowane są ciężarne z
              najtrudniejszymi powikłaniami ciąży z południowej Polski
              (zagrażające porody skrajnie przedwcześnie, niewydolności szyjki
              macicy, ciąże wielopłodowe i ich powikłania m.in. TTTS, konflikty
              serologiczne, zaburzenia ilości płynu owodniowego – mało- i
              wielowodzie, ciąże powikłane zaburzeniami wzrastania płodu, wadami
              płodu, w tym arytmiami serca płodu, ciężkimi chorobami i stanami
              matki).
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}

export default Perinatological;
