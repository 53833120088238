import React from "react";
import { Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import HorizontalLine from "../../util/HorizontalLine";
import Logo from "./../../../img/logo/logo_Femimed.webp";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import MailIcon from "@material-ui/icons/Mail";
import {Helmet} from "react-helmet"
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Info from "./../../util/Info.json"


function Contact() {
  return (
    <Grid container >
      <Helmet>
        <title>Centrum Medyczne FemiMed - Kontakt</title>
        <meta name="description" content="Centrum Medyczne FemiMed w Sosnowcu zaprasza do kontaktu tel. 668-259-378, 32-793-45-24. Adres e-mail: rejestracja@femi-med.pl. Adres: Kielecka 38A, Sosnowiec 41-219"/>
      </Helmet>
      <Grid item xs={1} />

      <Grid
        container
        item
        xs={10}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        justify="center"
        component={Paper}
        direction={"column"}
      >
        <Grid item xs>
          <Typography variant={"h3"} component={"h2"} align={"center"} style={{fontWeight:"500"}}>
            Kontakt
          </Typography>
        </Grid>

        <Grid item xs></Grid>
        <Grid
          item
          container
          direction={"row"}
          alignItems={"center"}
          justify={"center"}
          style={{ paddingTop: "50px" }}
        >
          <Grid item xs={6}>
            <Typography variant={"h5"} align={"center"}>
              Zapraszamy do kontaktu
            </Typography>
            <Typography align={"center"}>
              <img
                src={Logo}
                style={{ height: "50%", width: "50%" }}
                alt={"Logo FemiMed w Sosnowcu"}
              />
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify={"center"}
            >
              <Grid item>
                <PhoneAndroidIcon fontSize={"large"} style={{marginRight:"5px"}} />
              </Grid>
              <Grid item>
                <Typography align={"center"} variant={"h6"}>
                  {Info.mobilePhone}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify={"center"}
            >
              <Grid item>
                <PhoneIcon fontSize={"large"} style={{marginRight:"5px"}}/>
              </Grid>
              <Grid item>
                <Typography align={"center"} variant={"h6"}>
                  {Info.stationPhone}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify={"center"}
            >
              <Grid item>
                <MailIcon fontSize={"large"} style={{marginRight:"5px"}}/>
              </Grid>
              <Grid item>
                <Typography align={"center"} variant={"h6"}>
                  {Info.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify={"center"}
            >
              <Grid item>
                <LocationOnIcon fontSize={"large"} style={{marginRight:"5px"}}/>
              </Grid>
              <Grid item>
                <Typography align={"center"} variant={"h6"}>
                {Info.address}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={6} alignItems={"center"} justify={"center"}>
            <iframe
              title="femiMed"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10197.616486071456!2d19.17381266140701!3d50.28438382771717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716db0b997079fd%3A0xa7c2796b185f71a8!2sKielecka%2038A%2C%2041-219%20Sosnowiec!5e0!3m2!1spl!2spl!4v1618507118884!5m2!1spl!2spl"
              width="400"
              height="300"
              style={{ borderColor: "#000", borderWidth: "2px" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Grid>
        </Grid>
        <Grid item xs>
          <HorizontalLine />
        </Grid>
        <Grid item container alignItems={"center"} justify={"center"}></Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}

export default Contact;
