import React from "react";
import { Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet"

function Gynecology() {
  const useStyle = {
    paddingLeft: "15px",
  };
  return (
    <Grid
      container
      style={{
        paddingTop: "50px",
        paddingBottom: "50px",
        paddingLeft: "5px",
        paddingRight: "5px",
      }}
    >
      <Helmet>
        <title>
        Centrum Medyczne FemiMed - Ginekologia
        </title>
        <meta
          name="description"
          content="Specjaliści w Centrum Medycznym FemiMed w Sosnowcu zapewniają 
          profilaktykę z zakresu Ginekologii i Położnictwa oraz diagnostykę i leczenie problemów kobiet na każdym etapie życia od pokwitania do menopauzy proponując różnorodny zakres usług"
        />
      </Helmet>
      <Grid
        container
        item
        xs={12}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "25px",
          paddingBottom: "25px",
        }}
        justify="center"
        component={Paper}
        direction={"column"}
      >
        <Grid item xs>
          <Typography variant={"h4"} component={"h2"} align={"center"} style={{fontWeight:"500"}}>
            Ginekologia
          </Typography>
        </Grid>

        <Grid item container xs style={{ paddingTop: "25px" }}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant={"body1"} align={"justify"}>
              Specjaliści FemiMed z zakresu Ginekologii i Położnictwa zapewniają
              profilaktykę oraz diagnostykę i leczenie problemów kobiet na
              każdym etapie życia-od pokwitania do menopauzy-proponując
              różnorodny zakres usług:
            </Typography>
            <Typography
              variant={"body1"}
              style={{ paddingTop: "15px" }}
            >
              <li style={useStyle}>
                Posiewy bakteriologiczne z narządu rodnego
              </li>
              <li style={useStyle}>
                Badanie ultrasonograficzne przezpochwowe (TVS)
              </li>
              <li style={useStyle}>
                Dobór antykoncepcji hormonalnej (tabletka antykoncepcyjna,
                wkładki wewnątrzmaciczne, w tym zawierające hormon, implant
                antykoncepcyjny podskórny)
              </li>
              <li style={useStyle}>
                Wymazy cytologiczne w tym cienkowarstwowa cytologia na podłożu
                płynnym LBC (metoda dokładniejsza i bardziej czuła w stosunku do
                tradycyjnej cytologii)
              </li>
              <li style={useStyle}>
                Leczenie zachowawcze kłykcin kończystych sromu
              </li>
              <li style={useStyle}>
                Badanie w kierunku infekcji wirusem brodawczaka ludzkiego HPV
                (który jest odpowiedzialny za kłykciny kończyste oraz stany
                przednowotworowe i raka szyjki macicy)
              </li>
            </Typography>
            <Typography
              variant={"body1"}
              style={{ paddingTop: "15px" }}
            >
              <b>Podstawowa diagnostyka i leczenie niepłodności</b>
              <li style={useStyle}>Poradnictwo przed-koncepcyjne</li>
              <li style={useStyle}>Monitoring owulacji</li>
              <li style={useStyle}>
                Wykonanie testu w kierunku zakażeń uroginekologicznych, w tym
                Chlamydią, Mycoplasmą, Ureaplasmą
              </li>
              <li style={useStyle}>Stymulacja owulacji</li>
            </Typography>
            <Typography
              variant={"body1"}
              style={{ paddingTop: "15px" }}
            >
              <b>Endokrynologia ginekologiczna</b>
              <li style={useStyle}>
                Diagnostyka i leczenie zaburzeń miesiączkowania
              </li>
              <li style={useStyle}>
                Diagnostyka i leczenie zespołu policystycznych jajników
              </li>
              <li style={useStyle}>
                Diagnostyka i leczenie nadmiernego owłosienia u kobiet
              </li>
              <li style={useStyle}>
                Diagnostyka i leczenie hiperprolaktynemii
              </li>
            </Typography>
            <Typography
              variant={"body1"}
              style={{ paddingTop: "15px" }}
            >
              <b>Opieka nad kobietą w okresie menopauzalnym</b>
              <li style={useStyle}>Badania hormonalne</li>
              <li style={useStyle}>Dobór hormonalnej terapii zastępczej</li>
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Gynecology;
