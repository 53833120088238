import React from "react";
import { Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";
import TeamList from "./../../util/Team.json";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(22),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.primary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  columnExtended: {
    flexBasis: "60.00%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 1),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    backgroundColor: "#2b9c96",
  },
}));

function TeamMember(props) {
  const classes = useStyles();

  const doc = TeamList.doctor.find(
    (item) => item.linkTo === props.match.params.memberName
  );
  const images = require.context('../../../img/portrait', true);
  const loadImage = imageName => (images(`./${imageName}`).default);
  return (
    <Grid container>
      <Helmet>
        <title>Centrum Medyczne FemiMed - {doc.name}</title>
      </Helmet>
      <Grid item xs={1} />

      <Grid
        container
        item
        xs={10}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        justify="center"
        component={Paper}
        direction={"column"}
      >
        <Grid container alignItems={"center"}>

          <Grid item xs={2}/>
        <Grid item xs={8} >

          <Typography
            variant={"h3"}
            component={"h2"}
            align={"center"}
            style={{ fontWeight: "500" }}
          >
            {doc.title} {doc.name}

          </Typography>

        </Grid>
          <Grid item xs={2} align={"center"}>
            <Avatar
                src={doc.image !== null ? loadImage(`${doc.image}`): doc.alt}
                alt={doc.alt}
                className={classes.large}
            />
          </Grid>
        </Grid>


        <Grid item container xs style={{ paddingTop: "50px" }}>
          <Grid item xs={2} />
          <Grid item xs={8}>

            {doc.photo !== null ? <img src={doc.photo} alt={doc.name} /> : null}
            <Typography
              align={"left"}
              variant={"h3"}
              style={{ fontWeight: "500" }}
              className={classes.secondaryHeading}
            >
              {doc.spec1}
            </Typography>
            <Typography
              align={"left"}
              variant={"h3"}
              style={{ fontWeight: "500" }}
              className={classes.secondaryHeading}
            >
              {doc.spec2}
            </Typography>
            <Typography
              align={"left"}
              variant={"h3"}
              style={{ fontWeight: "500" }}
              className={classes.secondaryHeading}
            >
              {doc.spec3}
            </Typography>
            <Typography
              align={"left"}
              variant={"h3"}
              style={{ fontWeight: "500" }}
              className={classes.secondaryHeading}
            >
              {doc.spec4}
            </Typography>
            <Typography
              align={"left"}
              variant={"body1"}
              style={{ paddingTop: "10px" }}
            >
              {doc.description1}
            </Typography>
            {doc.description2 ? (
              <Grid item xs>
                <Typography align={"left"} variant={"body1"} style={{ paddingTop: "10px" }}>
                  <b>{doc.description2}</b>
                </Typography>
              </Grid>
            ) : null}
            {doc.description3 ? (
              <Grid item xs>
                {doc.description3.map((item, key) => (
                  <Typography key={key} align={"left"} variant={"body1"}>
                    {item}
                  </Typography>
                ))}
              </Grid>
            ) : null}
            {doc.description4 ? (
              <Grid item xs>
                <Typography align={"justify"} variant={"body1"} style={{ paddingTop: "10px" }}>
                  {doc.description4}
                </Typography>
              </Grid>
            ) : null}
            {doc.description6 ? (
                <Grid item xs>
                  <Typography align={"left"} variant={"body1"} style={{ paddingTop: "10px" }}>
                    <b>{doc.description6}</b>
                  </Typography>
                </Grid>
            ) : null}
            {doc.description5 ? (
                <Grid item xs>
                  {doc.description5.map((item, key) => (
                      <Typography key={key} align={"left"} variant={"body1"}>
                        {item}
                      </Typography>
                  ))}
                </Grid>
            ) : null}
            <Link
                to={"/zespol"}
                style={{textDecoration: "none",
                  color: "black"}}
            >
            <Button variant="contained" style={{marginTop:"50px", backgroundColor:"#2b9c96", color:"white"}}>Wróć</Button>
            </Link>

            {/* <Typography variant={"body1"} align={"justify"}>
              {doc.spec1}
              {doc.spec2}
              {doc.spec3}
              {doc.spec4}
              {doc.description1}
              {doc.description2}
              {doc.description3}
              {doc.description4}
            </Typography> */}
          </Grid>
          <Grid item xs={2} />

        </Grid>
      </Grid>

      <Grid item xs={1} />
    </Grid>
  );
}

export default TeamMember;
