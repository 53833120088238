import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { Grid } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  columnExtended: {
    flexBasis: "60.00%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    backgroundColor: "#2b9c96",
  },
}));

function Doctor(props) {
  const images = require.context('../../img/portrait', true);
  const loadImage = imageName => (images(`./${imageName}`).default);
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      style={{
        background: "none",
        border: "2px solid black",
        marginBottom: "15px",
        padding:"10px 0"
      }}
    >

      <Grid item xs={1} />
      <Grid item xs={10}  style={{ padding: "10px 0",width: "240px",
         }}>
        <Avatar
          alt={props.alt}
          src={props.image !== null ? loadImage(`${props.image}`): props.alt}
          style={{ margin: "auto" }}
          className={classes.large}
        />
        <Typography
          align={"center"}
          className={classes.heading}
          style={{ padding: "5px" }}
        >
          <b>{props.title}</b>
        </Typography>
        <Typography align={"center"} component="h2" className={classes.heading}>
          <b>{props.name}</b>
        </Typography>

        <CardActions disableSpacing style={{ justifyContent: "center" }}>
          <Link
            to={{ pathname: "/zespol/" + props.linkTo }}
            style={{ background: "#2b9c96", textDecoration: "none" }}
          >
            <Button size="medium" style={{ color: "white" }}>
              Czytaj Więcej
            </Button>
          </Link>
        </CardActions>
      </Grid>

      <Grid item xs={1} />
    </Grid>
  );
}

export default Doctor;

//
