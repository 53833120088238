import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import MailIcon from "@material-ui/icons/Mail";
import Info from "./../../util/Info.json"

const useStyles = makeStyles((theme) => ({
  footer: {
    // marginTop:"1rem",
    padding: "1rem",
    paddingTop: "2rem",
    backgroundColor: "#2b9c96",
    color: "white",
    position: "absolute",
    zIndex: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "38rem",

    // borderRadius:""
    // marginTop:'calc(5% - 60px)',
    // bottom:0,
    // backgroundColor:"black",
    // // position:"sticky"
  },
}));

function Footer() {
  const styles = useStyles();

  return (
    <Grid container className={styles.footer}>
      <Grid container >

      
      <Grid container item xs={12} direction="column" alignItems="flex-start">
        <Typography variant={"h5"} >
          Godziny
        </Typography>
        <Typography variant={"h5"} style={{ marginBottom: "10px" }}>
          Otwarcia
        </Typography>
        <Typography noWrap variant={"body1"}>
          <b>Pon - Pt</b>
        </Typography>
        <Typography noWrap variant={"body1"}>
          {Info.timeStart} - {Info.timeStop}
        </Typography>

        <Typography variant={"body1"} style={{ paddingTop: "5px" }}>
          {Info.closed}:
        </Typography>
        <Typography variant={"body1"}>Nieczynne</Typography>
      </Grid>

      <Grid container item xs={12} direction="column" alignItems="flex-start">
        <Typography variant={"h5"} style={{ marginBottom: "10px",paddingTop:"20px"}}>
          Kontakt
        </Typography>
        <Typography
          variant={"body1"}
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <PhoneAndroidIcon fontSize={"small"}  style={{marginRight:"5px"}}/>
          {Info.mobilePhone}
        </Typography>
        <Typography
          variant={"body1"}
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <PhoneIcon fontSize={"small"}  style={{marginRight:"5px"}}/>
          {Info.stationPhone}
        </Typography>

        <Typography
          variant={"body1"}
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <MailIcon fontSize={"small"}  style={{marginRight:"5px"}}/>
          {Info.email}
        </Typography>
      </Grid>
      
    </Grid>
    <Grid item xs={12} style={{paddingTop:"30px"}}>
    <Typography variant={"h5"} style={{ marginBottom: "10px" }}>
          Informacje
        </Typography>
        <Typography variant={"body2"} style={{marginBottom:"10px"}}>
        Nasza strona internetowa korzysta z plików cookies w celu dostosowania
        ustawień oraz zbierania anonimowych danych statystycznych. Za pomocą
        ustawień swojej przeglądarki możesz określić warunki przechowywania
        cookies.
        </Typography>
         
        <Link to="/polityka-prywatnosci" style={{color:"white"}}>
        Polityka Prywatności
        </Link>
    </Grid>
    </Grid>
  );
}

export default Footer;
