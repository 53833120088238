import React, { useState }  from 'react';
import { Grid, Paper} from "@material-ui/core";
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet"
import PriceListJSON from "./../../util/Prices.json";
import nl2br from 'react-newline-to-break';



function Prices() {
    const [PriceList] = useState(PriceListJSON);

    const priceStyle = {
        borderBottom:"1px solid black", 
        paddingTop:"15px",
      };
      const singlePriceStyle={
        fontWeight:"500",
        fontSize:"14px"
      }
    return (
        <Grid container style={
            {
            paddingTop: "50px", paddingBottom: "50px",
        paddingLeft: "5px",
        paddingRight: "5px"}}>
            <Helmet>
                <title>Centrum Medyczne FemiMed - Cennik</title>
                <meta name="description" content="Centrum FemiMed w Sosnowcu oferuje usługi najwyższej klasy w przystępnych cenach."/>
            </Helmet>

            <Grid container item xs={12}
                  style={{backgroundColor: "rgba(255,255,255,0.8)", paddingBottom: "25px"}}
                  justify="center" component={Paper} direction={"column"} >
                {PriceList.medicalService.map((item, key) => (
          <div key={key}>
            <Grid item xs key={"grid"+key} style={{paddingTop: "50px"}}>
              <Typography key={"title"+key} variant={"h4"} component={"h2"} align={"center"} style={{fontWeight:"500"}}>
                {nl2br(item.title)}
              </Typography>
            </Grid>
            
            <Grid container key={"innerMainGrid"+key} item xs={12} style={{ paddingTop: "25px" }}>
              <Grid item key={"innerLeftSideGrid"+key} xs={1}/>

              <Grid item key={"innerContentGrid"+key} xs={10}>

                  {item.subtitle != null ?
                      <Typography key={"title"+key} variant={"h5"} component={"h2"} align={"center"} style={{fontWeight:"500"}}>
                          {item.subtitle}
                      </Typography>

                      :null}

            {item.type.map((price,innerKey)=>(
              
              <Grid item container xs={12} key={"namePriceGrid"+innerKey} direction="row"   justify="space-between" style={priceStyle}
              >
                <Grid item container xs={7} direction={"column"}  alignItems="flex-start"   justify="center"
>
              <Typography key={"priceName"+innerKey} style={singlePriceStyle}>
              {nl2br(price.name)}
            </Typography>
            {price.comment ? <Typography key={"priceComment"+innerKey} style={{fontSize:"14px"}}> {nl2br(price.comment)}</Typography>: null}
            </Grid>
            <Grid item container xs={5}  direction={"column"}  alignItems="flex-end"   justify="center"
>

            <Typography key={"price"+innerKey} style={singlePriceStyle}>
              {price.price}
            </Typography>
            </Grid>

            </Grid>
                          
            
            ))}
            </Grid>
            </Grid>
            <Grid item xs={1}/>
          </div>
        ))}





            </Grid>


        </Grid>
    );
}

export default Prices;