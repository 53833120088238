import React from "react";
import {Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet"


function Obstetrics() {

  return (
    <Grid container >
      <Helmet>
        <title>Centrum Medyczne FemiMed - Położnictwo</title>
        <meta name="description" content="Prowadzenie ciąży w naszym Centrum Medycznym FemiMed opiera się na najwyższych standardach polskiej i światowej medycyny, 
        respektowaniu rekomendacji PTGiP, FMF, ISUOG dotyczących prowadzenia zarówno ciąży fizjologicznej, jak i ciąży z największymi patologiami"/>
      </Helmet>
      <Grid item xs={1} />

      <Grid
        container
        item
        xs={10}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        justify="center"
        component={Paper}
        direction={"column"}
      >
        <Grid item xs>
          <Typography variant={"h3"} component={"h2"}  align={"center"} style={{fontWeight:"500"}}>
            Położnictwo
          </Typography>
        </Grid>

        <Grid item container xs style={{paddingTop:"50px"}}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Typography variant={"body1"} align={"justify"}>
              Prowadzenie ciąży w naszym Centrum Medycznym opiera się na
              najwyższych standardach polskiej i światowej medycyny,
              respektowaniu rekomendacji PTGiP, FMF, ISUOG dotyczących
              prowadzenia zarówno ciąży fizjologicznej, jak i ciąży z
              największymi patologiami (m.in. ciąże mnogie, z konfliktem
              serologicznym, z obciążającym wywiadem położniczym, z
              towarzyszącymi chorobami wikłającymi ciążę). Pracujemy na
              najnowocześniejszym sprzęcie USG, zapewniamy pełną kontrolę nad
              stanem płodu, proponując również badania KTG.
            </Typography>
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              Otaczamy opieką od poczęcia do narodzin.
            </Typography>
            <Typography
              variant={"body1"}
              align={"justify"}
              style={{ paddingTop: "15px" }}
            >
              Nasi specjaliści pracują w szpitalnych Oddziałach
              Ginekologiczno-Położniczych w II i III stopniu referencyjności,
              zapewniając ciągłość opieki nad ciężarną i jej nowonarodzonym
              dzieckiem.
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}

export default Obstetrics;
