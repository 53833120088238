import React from "react";
import { Grid, Paper } from "@material-ui/core";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet"

function Ktg() {
  return (
    <Grid container style={{ paddingTop: "50px", paddingBottom: "50px",
    paddingLeft: "5px",
    paddingRight: "5px" }}>
      <Helmet>
        <title>
        Centrum Medyczne FemiMed - KTG
        </title>
        <meta name="description" content="Centrum Medyczne FemiMed w Sosnowcu. Kardiotokografia (KTG) to jedno z najważniejszych badań wykonywanych w
                            czasie ciąży."/>
      </Helmet>
      <Grid
        container
        item
        xs={12}
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          paddingTop: "25px",
          paddingBottom: "25px",
        }}
        justify="center"
        component={Paper}
        direction={"column"}
      >
        <Grid item xs>
          <Typography variant={"h4"} component={"h2"} align={"center"} style={{fontWeight:"500"}}>
            KTG
          </Typography>
        </Grid>

        <Grid item container xs style={{ paddingTop: "15px" }}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant={"body1"} align={"justify"}>
              Kardiotokografia (KTG) to jedno z najważniejszych badań wykonywanych w
              czasie ciąży. Pozwala kontrolować tak istotny parametr życiowy, jakim jest
              tętno płodu. Obrazuje też czynność skurczową mięśnia macicy. Jest
              niezastąpiona tuż przed porodem i w jego trakcie. Należy pamiętać o niej
              także wcześniej i wykonywać z częstością zaleconą przez lekarza.
            </Typography>
            <Grid item xs>
            <Typography variant={"h4"} component={"h3"} align={"center"} style={{fontWeight: "500", paddingTop: "15px", paddingBottom:"15px"}} >

              Wskazania do wykonania KTG
            </Typography>
            </Grid>
            <Typography variant={"body1"} align={"justify"}>
              Kardiotokografia jest zalecana u każdej kobiety będącej w trzecim trymestrze ciąży. Jest
              bardzo ważna w momencie porodu, a także wtedy, gdy minie jego termin. Może być
              konieczna wcześniej, jeżeli wystąpią niepokojące objawy. Należą do nich:
            </Typography>
            <Typography
                variant={"body1"}
                style={{ paddingLeft: "25px" }}
                paragraph={true}
            >
              <li>Niewyczuwalne lub zmniejszone ruchy płodu</li>
              <li>Krwawienie z pochwy</li>
              <li>Ból brzucha</li>
              <li>Podejrzenie zatrucia ciążowego</li>
              <li>Nadciśnienie tętnicze u matki</li>
              <li>Ciąża przenoszona, po 40 tygodniu</li>
              <li>Spowolnienie wrastania płodu</li>
              <li>Wykrycie wad serca u rozwijającego się płodu</li>
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Ktg;
