import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
// import Box from "@material-ui/core/Box";
// import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Parallax } from "react-parallax";
import BackgroundImage from "../../../img/background/femimed_background2.webp";
import Logo from "./../../../img/logo/logo_femimed.png";

// const useStylesForDropDown = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },
//   paper: {
//     marginRight: theme.spacing(2),
//   },
// }));

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 2,
    borderColor: "8px solid black",
  },
}));
const useStylesAppBar = makeStyles((theme) => ({
  root: {
    height: "75px",
    backgroundColor: "white",
    color: "black",
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  content: {
    // flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(22),
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    marginBottom: "12rem",
    // backgroundColor: "gray",
  },
}));
const useStalesFab = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderColor: "#000",
    borderWidth: "10px",
    // color: "black",
    width: "64px",
    height: "64px",
  },
});

function ScrollTop(props) {
  const { children } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function BackToTop(props) {
  // const stylesDropDown = useStylesForDropDown();
  const stylesAppBar = useStylesAppBar();
  const stylesFab = useStalesFab();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar>
        <Toolbar className={stylesAppBar.root}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={1} />
            <Grid
              container
              item
              xs={3}
              justify="flex-start"
              alignItems="center"
              direction="row"
            >
              <Link to={"/"}>
                <img
                  src={Logo}
                  style={{
                    maxWidth: "100%",
                    padding: "2% 0 2% 0",
                    maxHeight: "75px",
                    textAlign: "center",
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                  alt={"Logo Centrum Medycznego FemiMed"}
                />
              </Link>
            </Grid>
            <Grid item xs={1}></Grid>

            <Grid
              item
              container
              xs={7}
              justify="space-evenly"
              alignItems="center"
            >
              <Link to={"/"} className={stylesAppBar.link}>
                <Button>
                  <Typography>Strona główna</Typography>
                </Button>
              </Link>

              <div className={stylesAppBar.link}>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <Typography>Zakres Usług</Typography>
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <Link
                              to={"/badania-prenatalne"}
                              className={stylesAppBar.link}
                            >
                              <MenuItem onClick={handleClose}>
                                <Typography>Badania Prenatalne</Typography>
                              </MenuItem>
                            </Link>
                            <Link
                              to={"/ginekologia"}
                              className={stylesAppBar.link}
                            >
                              <MenuItem onClick={handleClose}>
                                <Typography>Ginekologia</Typography>
                              </MenuItem>
                            </Link>
                            <Link
                              to={"/poloznictwo"}
                              className={stylesAppBar.link}
                            >
                              <MenuItem onClick={handleClose}>
                                <Typography>Położnictwo</Typography>
                              </MenuItem>
                            </Link>
                            <Link
                              to={"/konsultacje-perinatologiczne"}
                              className={stylesAppBar.link}
                            >
                              <MenuItem onClick={handleClose}>
                                <Typography>
                                  Konsultacje Perinatologiczne
                                </Typography>
                              </MenuItem>
                            </Link>
                            <Link
                              to={"/diagnostyka-usg"}
                              className={stylesAppBar.link}
                            >
                              <MenuItem onClick={handleClose}>
                                <Typography>Diagnostyka USG</Typography>
                              </MenuItem>
                            </Link>
                            <Link to={"/ktg"} className={stylesAppBar.link}>
                              <MenuItem onClick={handleClose}>
                                <Typography>KTG</Typography>
                              </MenuItem>
                            </Link>
                            <Link
                              to={"/diagnostyka-laboratoryjna"}
                              className={stylesAppBar.link}
                            >
                              <MenuItem onClick={handleClose}>
                                <Typography>
                                  Diagnostyka Laboratoryjna
                                </Typography>
                              </MenuItem>
                            </Link>
                            {/*<Link*/}
                            {/*  to={"/medycyna-estetyczna"}*/}
                            {/*  className={stylesAppBar.link}*/}
                            {/*>*/}
                            {/*  <MenuItem onClick={handleClose}>*/}
                            {/*    <Typography>*/}
                            {/*      Medycyna Estetyczna*/}
                            {/*    </Typography>*/}
                            {/*  </MenuItem>*/}
                            {/*</Link>*/}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>

              <Link to={"/zespol"} className={stylesAppBar.link}>
                <Button>
                  <Typography>Zespół</Typography>
                </Button>
              </Link>
              {/* <Link to={"/cennik"} className={stylesAppBar.link}>
                <Button>
                  <Typography>Cennik</Typography>
                </Button>
              </Link> */}

              <Link to={"/rejestracja"} className={stylesAppBar.link}>
                <Button>
                  <Typography>Rejestracja</Typography>
                </Button>
              </Link>

              <Link to={"/kontakt"} className={stylesAppBar.link}>
                <Button>
                  <Typography>Kontakt</Typography>
                </Button>
              </Link>
            </Grid>

            {/*<Grid item xs={1}/>*/}
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />

      <Parallax
        disabled={true}
        bgImage={BackgroundImage}
        bgImageAlt="background"
        strength={820}
        bgImageStyle={{ height: "100vh", width: "100%", position: "fixed" }}
      >
        <div className={stylesAppBar.content}>
          {/*<Grid container>*/}
          {/*    <Grid item xs={12}>*/}
          {/*<Grid container>*/}

            {props.children}

          

          {/*        </Grid>*/}
          {/*    </Grid>*/}
          {/*</Grid>*/}
        </div>
      </Parallax>
      {/*<Container>*/}
      {/*    */}
      {/*    /!*<Box my={2}>*!/*/}
      {/*    /!*    {[...new Array(12)]*!/*/}
      {/*    /!*        .map(*!/*/}
      {/*    /!*            () => `Cras mattis consectetur purus sit amet fermentum.*!/*/}
      {/*    /!*Cras justo odio, dapibus ac facilisis in, egestas eget quam.*!/*/}
      {/*    /!*Morbi leo risus, porta ac consectetur ac, vestibulum at eros.*!/*/}
      {/*    /!*Praesent commodo cursus magna, vel scelerisque nisl consectetur etras mattis consectetur purus sit amet fermentum.*!/*/}
      {/*    /!*Cras justo odio, dapibus ac facilisis in, egestas eget quam.*!/*/}
      {/*    /!*Morbi leo risus, porta ac consectetur ac, vestibulum at eros.*!/*/}
      {/*    /!*Praesent commodo cursus magna, vel scelerisque niras mattis consectetur purus sit amet fermentum.*!/*/}
      {/*    /!*Cras justo odio, dapibus ac facilisis in, egestas eget quam.*!/*/}
      {/*    /!*Morbi leo risus, porta ac consectetur ac, vestibulum at eros.*!/*/}
      {/*    /!*Praesent commodo cursus magna, vel scelerisque niras mattis consectetur purus sit amet fermentum.*!/*/}
      {/*    /!*Cras justo odio, dapibus ac facilisis in, egestas eget quam.*!/*/}
      {/*    /!*Morbi leo risus, porta ac consectetur ac, vestibulum at eros.*!/*/}
      {/*    /!*Praesent commodo cursus magna, vel scelerisque niras mattis consectetur purus sit amet fermentum.*!/*/}
      {/*    /!*Cras justo odio, dapibus ac facilisis in, egestas eget quam.*!/*/}
      {/*    /!*Morbi leo risus, porta ac consectetur ac, vestibulum at eros.*!/*/}
      {/*    /!*Praesent commodo cursus magna, vel scelerisque niras mattis consectetur purus sit amet fermentum.*!/*/}
      {/*    /!*Cras justo odio, dapibus ac facilisis in, egestas eget quam.*!/*/}
      {/*    /!*Morbi leo risus, porta ac consectetur ac, vestibulum at eros.*!/*/}
      {/*    /!*Praesent commodo cursus magna, vel scelerisque ni.`,*!/*/}
      {/*    /!*        )*!/*/}
      {/*    /!*        .join('\n')}*!/*/}
      {/*    /!*</Box>*!/*/}
      {/*</Container>*/}

      <ScrollTop>
        <Fab
          className={stylesFab.root}
          size="medium"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
