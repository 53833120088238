import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Parallax } from "react-parallax";
import BackgroundImage from "../../../img/background/femimed_background2_mobile.webp";
import Logo from "./../../../img/logo/logo_femimed.png";

// const useStylesForDropDown = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },
//   paper: {
//     marginRight: theme.spacing(2),
//   },
// }));
const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    height: "75px",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const useNestedList = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const fabStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 2,
    borderColor: "8px solid black",
  },
}));
const useStylesAppBar = makeStyles((theme) => ({
  root: {
    height: "75px",
    backgroundColor: "white",
    color: "black",
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  content: {
    // flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(16),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    marginBottom: "28rem",
    // backgroundColor: "gray",
  },
}));
const useStalesFab = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderColor: "#000",
    borderWidth: "10px",
    // color: "black",
    width: "64px",
    height: "64px",
  },
});

function ScrollTop(props) {
  const { children } = props;
  const classes = fabStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function BackToTop(props) {
  const theme = useTheme();
  const stylesNestedList = useNestedList();

  const classes = useStyles();
  // const stylesDropDown = useStylesForDropDown();
  const stylesAppBar = useStylesAppBar();
  const stylesFab = useStalesFab();
  const [open, setOpen] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);

  const handleClick = () => {
    setOpenList(!openList);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={stylesAppBar.root}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Grid container>
            <Grid item xs={2} />
            <Grid
              container
              item
              xs={10}
              justify="flex-end"
              alignItems="center"
              direction="row"
            >
              <Link to={"/"}>
                <img
                  src={Logo}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "75px",
                    paddingTop: "10px",
                  }}
                  alt={"centrum medyczne femimed logo"}
                />
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />

      <Parallax
        disabled={true}
        bgImage={BackgroundImage}
        bgImageAlt="background"
        strength={820}
        bgImageStyle={{
          height: "100vh",
          minWidth: "100%",
          maxWidth: "auto",
          position: "fixed",
        }}
      >
        <div className={stylesAppBar.content}>
          {/*<Grid container>*/}
          {/*    <Grid item xs={12}>*/}
          {/*<Grid container>*/}

          {props.children}

          {/*        </Grid>*/}
          {/*    </Grid>*/}
          {/*</Grid>*/}
        </div>
      </Parallax>

      <ScrollTop>
        <Fab
          className={stylesFab.root}
          size="medium"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            component={Link}
            to={"/"}
            onClick={handleDrawerClose}
          >
            {/* <ListItemIcon></ListItemIcon> */}
            <ListItemText primary={"Strona główna"} />
          </ListItem>

          <ListItem button onClick={handleClick}>
            <ListItemText primary="Zakres Usług" />
            {openList ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={stylesNestedList.nested}
                component={Link}
                to={"/badania-prenatalne"}
                onClick={handleDrawerClose}
              >
                <ListItemText primary={"Badania Prenatalne"} />
              </ListItem>
              <ListItem
                button
                className={stylesNestedList.nested}
                component={Link}
                to={"/ginekologia"}
                onClick={handleDrawerClose}
              >
                <ListItemText primary={"Ginekologia"} />
              </ListItem>
              <ListItem
                button
                className={stylesNestedList.nested}
                component={Link}
                to={"/poloznictwo"}
                onClick={handleDrawerClose}
              >
                <ListItemText primary={"Położnictwo"} />
              </ListItem>
              <ListItem
                button
                className={stylesNestedList.nested}
                component={Link}
                to={"/konsultacje-perinatologiczne"}
                onClick={handleDrawerClose}
              >
                <ListItemText primary={"Konsultacje Perinatologiczne"} />
              </ListItem>
              <ListItem
                button
                className={stylesNestedList.nested}
                component={Link}
                to={"/diagnostyka-usg"}
                onClick={handleDrawerClose}
              >
                <ListItemText primary={"Diagnostyka USG"} />
              </ListItem>
              <ListItem
                button
                className={stylesNestedList.nested}
                component={Link}
                to={"/ktg"} onClick={handleDrawerClose}
              >
                <ListItemText primary={"KTG"} />
              </ListItem>
              <ListItem
                button
                className={stylesNestedList.nested}
                component={Link}
                to={"/diagnostyka-laboratoryjna"}
                onClick={handleDrawerClose}
              >
                <ListItemText primary={"Diagnostyka Laboratoryjna"} />
              </ListItem>
              {/*<ListItem*/}
              {/*  button*/}
              {/*  className={stylesNestedList.nested}*/}
              {/*  component={Link}*/}
              {/*  to={"/medycyna-estetyczna"}*/}
              {/*  onClick={handleDrawerClose}*/}
              {/*>*/}
              {/*  <ListItemText primary={"Medycyna Estetyczna"} />*/}
              {/*</ListItem>*/}
            </List>
          </Collapse>
          <ListItem
            button
            component={Link}
            to={"/zespol"}
            onClick={handleDrawerClose}
          >
            <ListItemText primary={"Zespół"} />
          </ListItem>
        </List>
        <Divider />
        <List>
          {/* <ListItem
            button
            component={Link}
            to={"/cennik"}
            onClick={handleDrawerClose}
          >
            <ListItemText primary={"Cennik"} />
          </ListItem> */}
          <ListItem
            button
            component={Link}
            to={"/rejestracja"}
            onClick={handleDrawerClose}
          >
            <ListItemText primary={"Rejestracja"} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={"/kontakt"}
            onClick={handleDrawerClose}
          >
            <ListItemText primary={"Kontakt"} />
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );
}
